<template>
  <div class="layout-full">
    <app-navbar v-if="showNavbar" />

    <layout-full-content-render>
      <router-view />
    </layout-full-content-render>

    <app-footer />
  </div>
</template>

<script>
import LayoutFullContentRender from '@/layouts/full/components/LayoutFullContentRender.vue';
import AppNavbar from '@/layouts/components/Navbar.vue';
import AppFooter from '@/layouts/components/Footer.vue';

export default {
  components: {
    LayoutFullContentRender, 
    AppNavbar,
    AppFooter
  },
  computed: {
    showNavbar () {
      return this.$route.name !== 'auth-login';
    }
  },
};
</script>
